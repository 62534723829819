

:root {
	--padding: 15px 
}

@media screen and (min-width: 750px) {
	:root {
    --padding: 30px 
  }
}



.hidden {
  display: none;
}


 
body {
  height: 100%;
}

.sectionsRevealed {
  background-color: black;
  color: #f4dabe;
}

.subtext {
  color: orange;
}

h3 {
  position: absolute;
  color: orange;
}









a {
  text-decoration: none;
}

a:visited { text-decoration: none; color: black;}
a:hover { text-decoration: none; }
a:focus { text-decoration: none; }
a:hover, a:active { text-decoration: none; }


h2 {
  font-size: 30px;
  font-family:'Times New Roman', Times, serif;
}

.name {
  position: absolute;
  left: 30px;
  font-family: sans-serif;
}

@media only screen and (min-width: 500px) {
  .name:hover {
    color: grey;
    cursor: pointer;
  }
}



.email {
  position: absolute;
  left: 30px;
  top: 48px;
  font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  font-weight: 400;
  z-index: -1000;
}

@media only screen and (max-width: 500px) {
  .email {
    top: 24px;
    right: 10px;
    text-align: right;
  }
}


.LinkedInLogo {
  position: absolute;
  width: 25px;
  height: 25px;
  left: 30px;
  top: 80px;
}

.GitHubLogo {
  position: absolute;
  width: 20px;
  height: 20px;
  left: 32px;
  top: 110px;
}


.LinkedInLogo:hover {
  cursor: pointer;
  transform: rotate(90deg);
}

.GitHubLogo:hover {
  cursor: pointer;
  transform: rotate(90deg);
}





h1 {
  font-size: 15px;
  font-family:'Times New Roman', Times, serif;
  left: 45vw;
  position: absolute;
}


@media only screen and (min-width: 500px) {
  h1:hover {
    color: grey;
    cursor: pointer;
  }
 }



 
 
 
 
 
 
 
 
 
 .organizations {
   left: 43vw;
   top: 6vh;
 }
 
 .websites {
  left: 43vw;
  top: 25vh
}

.books {
  left: 43vw;
  top: 43vh
}

.songs {
  left: 43vw;
  top: 58vh
}

.classes {
  left: 43vw;
  top: 72.5vh
}

 
 
 
 
 
 
 
 
 
 
 
.zero {
  top: 8vh;
}

.one {
  top: 14vh;
}

@media only screen and (min-width: 500px) {
  .one:hover {
    color: #3d6b1f;
  }
 }




.two {
  top: 25vh;
}

.three {
  top: 29vh;
}

.four {
  top: 33vh;
}

.five {
  top: 37vh;
}




.six {
  top: 48.5vh;
}

.seven {
  top: 53vh;
}





.nine {
  top: 63.7vh;
}

.ten {
  top: 67.5vh;
}




.eleven {
  top: 78vh;
}

.twelve {
  top: 82vh;
}

.thirteen {
  top: 86vh;
}

.fourteen {
  top: 90vh;
}






.infoRevealButton {
  position: fixed;
  border-radius: 100%;
  width: 8vh;
  height: 8vh;
  bottom: 3vh;
  right: 3vw;
  border: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  user-select: none;
  text-overflow: none;
}

.spanContainer {
  position: absolute;
  left: 24px;
  font-size: 8vh;
}

@media only screen and (max-width: 500px) {
  .infoRevealButton {
    width: 10vh;
    height: 10vh;
    border-radius: 30%;
  }
  
  .spanContainer {
    position: default;
  }
}


.infoRevealButton:hover {
  cursor: pointer;
}














.menloInnovationsWrapper {
  height: 100%; 
  width: 100%;
  position: fixed;
}

.menloInnovationsName {
  position: absolute;
  left: 30px;
}

.menloInnovationsName:hover {
  color: grey;
  cursor: pointer;
}

.menloInnovationsInfo {
  position: absolute;
  color: black;
  left: 186px;
  /* top: 3px;
  font-size: 28px; */
}

.usaaLink {
  position: absolute;
  color: #182475;
  font-size: 5vw;
  font-family: Arial, Helvetica, sans-serif;
  z-index: -1000;
}

.usaaLink:hover {
  color: grey;
  cursor: pointer;
}

.menloInnovationsLink {
  position: absolute;
  color: #f68f1e;
  font-size: 5vw;
  font-family: Arial, Helvetica, sans-serif;
  z-index: -1000;
  /* transform: rotate(20deg); */
}

.menloInnovationsLink:hover {
  color: grey;
  cursor: pointer;
}

@media only screen and (max-width: 800px) {
  .menloInnovationsLink {
    left: 25vw;
    top: 13vh;
    font-size: 5.5vh;
  }
}










.parchmentInfo {
  color: black;
  font-weight: 800;
  top: 400px;
  left: 69vh;
  position: absolute;
}














.whoWillSurviveWrapper {
  height: 100%; 
  width: 100%;
  position: fixed;
  background-color: #f84949;
}

.title {
  left: 15vw;
  top: 15vh;
  position: absolute;
}

.title:hover {
  color: #4f4f4f;
  cursor: pointer;
}

.whoWillSurviveInfo {
  left: 40vw;
  top: 40vh;
  position: absolute;
}







.magaWrapper {
  height: 100%; 
  width: 100%;
  position: fixed;
  background-color: white;
}

#magaName {
  color: black;
}

#magaName:hover {
  color: grey;
  cursor: pointer;
}

.magaTitle {
  left: 15vw;
  top: 15vh;
  position: absolute;
  color: red;
}

.magaTitle:hover {
  color: #4f4f4f;
  cursor: pointer;
}


.magaInfo {
  left: 40vw;
  top: 40vh;
  position: absolute;
  color: blue;
}









.physicsTitle {
  position: absolute;
  right: 40vw;
  top: 20vh;
  font-size: 25px;
  font-family: Arial, Helvetica, sans-serif;
}

.physicsInfo {
  position: absolute;
  right: 20vw;
  bottom: 20vh;
  text-align: center;
  font-weight: 800;
  color: red;
}





.calcTitle {
  position: absolute;
  right: 40vw;
  top: 20vh;
  font-size: 25px;
  font-family: Arial, Helvetica, sans-serif;
}

.calcInfo {
  position: absolute;
  right: 20vw;
  bottom: 20vh;
  text-align: center;
  font-weight: 800;
  color: red;
}

.integrals, .lineIntegrals {
  font-size: 3vh;
  position: absolute;
}

.lineIntegrals {
  top: 1vh;
  color: red;
}


.integrals {
  font-size: 5vh;
  position: absolute;
  right: 0vw;
  bottom: 0vh;
  transform: rotate(25deg);
  color:  blue;
}

#int2 {
  transform: rotate(0deg);
}

#int3 {
  transform: rotate(90deg);
}

#int4 {
  transform: rotate(30deg);
}

#int5 {
  transform: rotate(2deg);
}

#int6 {
  transform: rotate(120deg);
}



.info {
  position: absolute;
  top: 60vh;
  left: 30vw;
}










.wrapper {
  height: 100%; 
  width: 100%;
  position: fixed;
  
}

.linearTitle {
  position: absolute;
  top: 0vh;
  font-size: 6vh;
  max-height: 100vh;
  overflow: hidden;
  font-family: Arial, Helvetica, sans-serif;
}


.innerText {
  color: red;
}







@media only screen and (max-width: 800px) {
  .discreteTitle {
    font-size: 3vw;
  }
}


.discreteTitle {
  position: absolute;
  top: 20vh;
  font-size: 2vh;
  left:1vw;
}













